<template>
  <div class="background">
    <template v-if="loading">
      <DGShimComponent />
    </template>
    <template v-else>
      <div class="dgs-container">
        <div class="title">
          <div class="title__message">
            <h3>{{ $t("dg_suite.overview.heading") }}</h3>
            <div v-html="markdown($t('dg_suite.overview.intro'))" />
          </div>
          <div class="title__image"></div>
        </div>
        <div class="content">
          <div class="content__section" v-for="(group, index) in groups" :key="index">
            <div class="content__section__title">
              <h3>{{ group.title }}</h3>
              <p>{{ group.description }}</p>
            </div>

            <div class="content__section__wrapper">
              <div class="content__section__body" v-for="(product, i) in group.products" :key="i">
                <router-link
                  :to="{ name: 'DGSuiteProductDetails', params: { product_id: product.id } }"
                  class="content__box content__box__link"
                >
                  <div
                    class="content__box__image"
                    :style="{ backgroundImage: 'url(' + mainImage(product).url + ')' }"
                  ></div>
                  <div class="content__box__title">
                    <h3>{{ product.short_name }}</h3>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2 12H22"
                        stroke="#003349"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 5L22 12L15 19"
                        stroke="#003349"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DGShimComponent from "../components/DGShimComponent";

export default {
  name: "DGSuite",
  components: {
    DGShimComponent,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["fetchGroups"]),
    mainImage(product) {
      return product.images.find(element => element.type === "main");
    },
  },
  computed: {
    ...mapState({
      groups: state => state.dg_suite.groups,
    }),
  },
  async created() {
    try {
      await this.fetchGroups();
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.dgs-container {
  max-width: 1120px;
  margin: 0 auto;
}

body,
p,
ul,
ol,
li,
input,
textarea,
select,
a,
span {
  @include font_weight("Regular");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font_weight("Semibold");
}

.background {
  background: linear-gradient(180deg, #dfe4e9 0%, #f2f4f6 38.96%);
  min-height: 90vh;
}

.title {
  display: flex;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  padding-top: 55px;
  justify-content: center;
}

.title__image {
  background-image: url("../images/DGSuite.svg");
  width: 300px;
  height: 240px;
  margin-left: 60px;
}

.title__message {
  flex: 1;

  h3 {
    font-size: $f_xx_large;
    line-height: 32px;
    font-weight: bold;
    padding-bottom: 14px;
    hyphens: manual;
  }

  & ::v-deep p {
    @include font_weight("Regular");
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 24px;
  justify-content: center;
  padding-top: 52px;
}

.content__section {
  padding: 16px;
  border: 1px $light solid;
  background-color: $white;

  &__title {
    h3 {
      font-size: 24px;
      line-height: 32px;
      margin: 0;
    }
  }
  &__wrapper {
    display: flex;
    gap: 32px;
    margin-top: 16px;

    & + .content__section {
      margin-top: 24px;
    }
  }
}

.content__box {
  background: #ffffff;
  box-sizing: border-box;
  border: 1px $light solid;
  transition: 0.2s;
  position: relative;
  top: 0;
  flex: 1;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__link {
    text-decoration: none;
    color: #003349;
  }
}

.content__box:hover {
  top: -2px;
  box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.15);
}

.content__box__image {
  height: 120px;
  width: 360px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.content__box__body {
  padding: 16px;
  padding-top: 0;
  font-size: 14px;
  line-height: 20px;
}
.content__box__title {
  padding: 16px;
  text-align: left;
  align-self: stretch;
  display: flex;

  h3 {
    margin: 0;
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    flex: 1;
  }
}
</style>
