<template>
  <div class="shimmer-wrapper">
    <div class="shine box"></div>

    <div class="shim-container">
      <div class="shine lines"></div>
      <div class="shine lines"></div>
      <div class="shine lines"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DGShimComponent",
};
</script>
